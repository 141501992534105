<template>
  <transition name="modal">
    <div class="modal-mask" @keyup.esc="close" v-if="state.open" ref="mask" tabindex="0">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"><div/></slot>
            <button v-if="cross" class="modal-default-button" @click="$emit('close')">
              <icon-close :class="'close_icon'"></icon-close>
            </button>
          </div>
          <div :class="{ 'modal-body': true, media }">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconClose from '@/atoms/icons/close'

export default {
  name: 'hippolyte.atom.modal',
  components: {
    IconClose
  },
  props: {
    media: {
      type: Boolean,
      default: false
    },
    cross: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      state: {
        open: true
      }
    }
  },
  mounted () {
    if (this.$refs.mask) {
      this.$refs.mask.focus()
    }
  },
  emits: ['close'],
  methods: {
    close () {
      this.state.open = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="stylus">
@import '../colors'
@import '../mixins'
.modal-mask
  position fixed
  z-index 9998
  top 0
  left 0
  width 100%
  height 100%
  background-color rgba(0, 0, 0, 0.3)
  display table
  transition opacity 0.3s ease
  color #000
  .modal-wrapper
    display table-cell
    vertical-align middle
    .modal-container
      display flex
      flex-direction column
      container()
      width 'calc(85% - %s)' % (2 * $container-padding)
      max-width 500px
      margin auto
      background-color #fff
      transition all 0.3s ease
      .modal-header
        display flex
        align-content center
        justify-content space-between
      .modal-body
        margin 20px 0
        &.media
          display flex
          align-items center
          >:last-child
            width 100%
            text-align center
          >:first-child
            margin auto 1em auto 0.5em
      .modal-footer
        text-align right
.modal-enter
  opacity 0
.modal-leave-active
  opacity 0
.modal-enter .modal-container,
.modal-leave-active .modal-container
  -webkit-transform scale(1.1)
  transform scale(1.1)
</style>
