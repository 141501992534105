import '@/version.js'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import config from 'minou/src/config'
import Context from './context'
import Toaster from '@meforma/vue-toaster'
import register from './registerServiceWorker'
import { createI18n } from 'vue-i18n'
import i18n from './i18n'

if (import.meta.env.PROD) {
  register()
}

const vi18n = createI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: ['en', 'fr'],
  warnHtmlInMessage: 'off',
  messages: i18n.messages
})

const app = createApp(App)
config
  .load('config')
  .then(async function (data) {
    const context = new Context(data)
    window.ywcontext = context
    context.load()
    context.inject(app)
    app
      .use(vi18n)
      .use(router(context))
      .use(Toaster, {
        position: 'bottom-right',
        duration: 3000
      })
      .mount('#app')
  })
