<template>
  <svg v-bind="$props" viewBox="0 0 18 19">
    <path d="M1 17.4V16.4C1 12.534 4.58173 9.40002 9 9.40002C13.4183 9.40002 17 12.534 17 16.4V17.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 7.40002C10.6568 7.40002 12 6.05685 12 4.40002C12 2.74317 10.6568 1.40002 9 1.40002C7.34314 1.40002 6 2.74317 6 4.40002C6 6.05685 7.34314 7.40002 9 7.40002Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import icon from './mixins'

export default {
  name: 'hippolyte.atom.icon.profile',
  mixins: [icon('18.16px', '23.349px')]
}
</script>
