import Socket from 'minou/src/socket'
import Auth from 'minou/src/auth'
import Chat from 'minou/src/chat'
import Account from '@/entities/account'
import Candidate from '@/entities/candidate'
import Conciliation from '@/entities/conciliation'
import Favico from 'favico.js'
import { EventEmitter } from 'eventemitter3'
import debug from 'debug'
const log = debug('context')
const boot = debug('context:boot')

function mustAuthRoute (hash) {
  const splited = hash.split('/')
  return splited.length > 1
    ? !['login','jobs', 'insights'].includes(splited[1].split('?')[0])
    : true
}

export default class Context {
  constructor (config) {
    this.loader = {
      session: false,
      candidate: false,
      chat: false
    }
    this.config = config
    this.socket = new Socket(null, undefined, this.config)
    this.auth = new Auth(this.socket)
    this.listenSocket()
    this.socket.auth = this.auth
    this.loaded = false
    this.candidate = new Candidate()
    this.chat = new Chat(this.socket, this.config)
    this.favicons = document
      ? Array.from(document.querySelectorAll('head link[rel="shortcut icon"]')).map(el => new Favico({
        animation: ('fade'),
        element: el
      }))
      : []
  }

  listenSocket () {
    this.connected = { state: null }
    this.socket.on('connect', () => {
      log('connected')
      this.connected.state = true
    })
    this.socket.on('disconnect', () => {
      log('disconnected')
      this.connected.state = false
    })
  }

  async load () {
    this.loader.session = this.loadSession()
    this.loader.candidate = this.loadCandidate()
    this.loader.chat = this.loadChat()
    boot('loading start')
    const done = await Promise.all(Object.values(this.loader))
    boot('loading done')
    return done
  }

  async loadSession () {
    boot('loading session start')
    const login = await this.ensureLogin()
    boot('loading session done')
    if (this.auth.user) {
      this.socket.getSocket().on('disconnect', () => this.favicons.forEach(f => f.badge('🔌')))
      this.socket.getSocket().on('connect', () => this.favicons.forEach(f => f.reset()))
      this.loaded = true
    }
    return login
  }

  async loadCandidate () {
    await this.loader.session
    if (this.isLogged()) {
      log('loading candidate')
      const candidate = await Candidate.getByChatUser(this.auth.user.id, this.socket)
      this.candidate.setData(candidate)
      boot('loading candidate done')
    }
  }

  async ensureLogin () {
    const parsed = new URL(document.location.href)
    if (parsed.searchParams.has('token')) {
      boot('ensure login with token')
      try {
        this.auth.logout()
        this.socket.session = null
        await this.socket.getSession()
        const token = parsed.searchParams.get('token')
        const user = await this.socket.service('auth_token/USE', { token })
        this.auth.setUser(user)
        await this.socket.auth.renewToken()
      } catch {
        parsed.hash = '/login'
      }
      parsed.searchParams.delete('token')
      boot('reloading 1')
      window.location.replace(parsed.toString())
      return new Promise(() => null)
    }
    await this.socket.getSession()
    if (mustAuthRoute(parsed.hash)) {
      boot('is authed ?')
      if (!this.isLogged()) {
        parsed.hash = '/login'
        boot('reloading 2')
        parsed.pathname = parsed.pathname.slice(-2) === '//'
          ? parsed.pathname.slice(0, -1)
          : parsed.pathname + '/'
        window.location.replace(parsed.toString())
        return new Promise(() => null)
      }
      if (!this.auth.user.roles) {
        await this.auth.getToken()
      }
    }
    boot('ensure auth ok')
  }

  isLogged () {
    return this.auth.user && Boolean(this.auth.user.id)
  }

  async loadChat () {
    await this.loader.session
    if (this.isLogged()) {
      boot('loading chat start')
      await this.chat.loadRooms()
      const accounts = this.chat.rooms.map(r => {
        return new Account({ id: r.id.split('/').reverse()[1] })
      })
      await Account.loadIds(accounts, this.socket)
      this.chat.rooms.forEach(room => {
        const [, accountId, ...prefix] = room.id.split('/').reverse()
        const account = accounts.find(a => a.id === accountId)
        room.conciliation = new Conciliation({
          recruiter: account.recruiter,
          account,
          candidate: this.candidate,
          domain: prefix.reverse().join('/')
        }, this.socket)
        // @fixme no more accurate started status
        room.conciliation.status = 'started'
      })
      this.chat.rooms.sort(Conciliation.compare)
      if (this.chat.rooms[0]) {
        await this.chat.join(this.chat.rooms[0])
      }
      boot('loading chat done')
    }
  }

  async listenRooms () {
    this.chat
      .on('room:add', this.onRoomAdd, this)
      .on('room:remove', this.onRoomRemove, this)
    this.socket.on('reconnect', () => {
      this.chat.rooms.map(room => room.history())
    })
  }

  async onRoomAdd (data) {
    if (this.chat.roomId(data.id)) {
      return
    }
    const [, id, ...prefix] = data.id.split('/').reverse()
    const account = await Account.create({ id }).load(this.session)
    const conciliation = new Conciliation({
      recruiter: account.recruiter,
      candidate: this.candidate,
      account,
      domain: prefix.reverse().join('/')
    }, this.socket)
    // @fixme no more accurate started status
    conciliation.status = 'started'
    const room = await this.chat.create(Object.assign({ conciliation }, data), false)
    if (this.chat.rooms.length === 1) {
      this.chat.join(room)
    }
    this.chat.emit('room:update', room)
  }

  async onRoomRemove (room) {
    if (!room) { return }
    await this.chat.close(room, false)
    room.conciliation = null
    this.chat.emit('room:update', room)
  }

  inject (app) {
    Object.assign(
      app.config.globalProperties,
      {
        $socket: this.socket,
        $auth: this.auth,
        $config: this.config,
        $connected: this.connected,
        $candidate: this.candidate,
        $chat: this.chat,
        $loader: this.loader,
        $bus: new EventEmitter()
      }
    )
  }
}
