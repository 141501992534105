export function capitalize (text) {
  if (typeof text === 'string' && text.length > 0) {
    return text.split(' ')
      .map(capitalizeFirst)
      .join(' ')
  }
  return text
}

export function capitalizeFirst ([first, ...rest]) {
  return first
    ? [first.toUpperCase()].concat(rest).join('')
    : ''
}

export function randId (id, rank = 0) {
  return parseInt(id.replace(/-/g, '')[rank], 16) / 16
}

export function uniq () {
  return Math.random().toString(20).substr(2, 8)
}

export const factories = new WeakMap()

// @todo size limit
export function factory (Entity, data, socket) {
  let f
  if (factories.has(Entity)) {
    f = factories.get(Entity)
  } else {
    f = new Map()
    factories.set(Entity, f)
  }
  if (data && data.id && f.has(data.id)) {
    const e = f.get(data.id)
    e.setData(data)
    return e
  }
  const entity = new Entity(data, socket)
  if (entity.id) {
    f.set(entity.id, entity)
    entity.watch()
  }
  return entity
}

export function collectIds (data, acc = []) {
  if (typeof data === 'string' && data.length > 0) {
    return acc.concat([data])
  }
  if (Array.isArray(data)) {
    return data.reduce((acc, d) => collectIds(d, acc), [])
  }
  if (typeof data === 'object' && data !== null && 'id' in data) {
    return collectIds(data.id, acc)
  }
  return acc
}

// source https://raw.githubusercontent.com/kennethjiang/js-file-download
export function download (blob, filename) {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}
