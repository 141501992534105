import debug from 'debug'
const log = debug('analityc')

export function gtag () {
  if (import.meta.NODE_ENV === 'production') {
    window.dataLayer.push(arguments)
  }
  log('add tag %o', arguments)
}
if (!window.dataLayer) {
  window.dataLayer = window.dataLayer || []
}
