import EventEmitter from 'eventemitter3'
import SW from '@/registerServiceWorker'

// @source https://www.npmjs.com/package/web-push
function urlBase64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')
  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
let memo = null
let statusRequest = null

export default class Notif extends EventEmitter {
  constructor (user, socket) {
    super()
    this.user = user
    this.socket = socket
    this.registered = null
    if (user.id) {
      this.listen()
    }
  }

  listen () {
    return this.socket.sub(
      `/user/${this.user.id}`,
      'NOTIFY/STATUS',
      async data => {
        if (await this.isOurDevice(data.body.endpoint)) {
          this.registered = data.body.status
          this.emit('update', this.registered)
        }
      }
    )
  }

  async isOurDevice (endpoint) {
    return Notification?.permission === 'granted' && (await this.register()).endpoint === endpoint
  }

  getSubscribeOptions () {
    return {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(this.socket.config.vapidPublicKey)
    }
  }

  async register () {
    const sw = await SW()
    await this.askPermission()
    if (!memo) {
      memo = await sw.pushManager.subscribe(this.getSubscribeOptions())
    }
    return memo
  }

  async save (register) {
    register = JSON.parse(JSON.stringify(register))
    return this.socket.service('notify/REGISTER', { uid: this.user.id, register })
  }

  async remove () {
    if (Notification?.permission === 'granted') {
      const register = JSON.parse(
        JSON.stringify(
          await this.register()
        )
      )
      return this.socket.service('notify/UNREGISTER', { uid: this.user.id, register })
    }
  }

  async status () {
    if (this.hasPermission()) {
      const register = JSON.parse(
        JSON.stringify(
          await this.register()
        )
      )
      try {
        if (!statusRequest) {
          statusRequest = this.socket.service('notify/STATUS', { uid: this.user.id, register })
        }
        const { registered } = await statusRequest
        statusRequest = null
        if (this.registered !== registered) {
          this.registered = registered
          this.emit('update', this.registered)
        }
        return registered
      } catch (err) {
        statusRequest = null
        if (this.registered !== false) {
          this.registered = false
          this.emit('update', this.registered)
        }
        return false
      }
    }
    return false
  }

  hasPermission () {
    return Notification?.permission && Notification.permission === 'granted'
  }

  askPermission () {
    if (Notification?.permission === 'blocked') {
      return Promise.reject(new Error('permission rejected'))
    }
    return (new Promise((resolve, reject) => {
      try {
        try {
          Notification.requestPermission().then(resolve, reject)
        } catch {
          Notification.requestPermission(r => resolve(r))
        }
      } catch (err) {
        reject(err)
      }
    })).then(function (grant) {
      if (grant !== 'granted') {
        throw new Error('We weren\'t granted permission.')
      }
    })
  }

  destroy () {
    this.removeListener()
  }
}
Notif.support = {
  sw: 'serviceWorker' in navigator,
  push: 'PushManager' in window,
  notif: 'Notification' in window
}
