<template>
  <svg v-bind="$props" viewBox="0 0 18 18">
    <path d="M8.99991 11.4C10.3254 11.4 11.3999 10.3255 11.3999 9.00001C11.3999 7.67449 10.3254 6.60001 8.99991 6.60001C7.67439 6.60001 6.59991 7.67449 6.59991 9.00001C6.59991 10.3255 7.67439 11.4 8.99991 11.4Z" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.0979 7.71632L14.2198 5.59584L15.4 4.2L13.8 2.6L12.4118 3.78636L10.2462 2.89579L9.74824 1H8.1848L7.67928 2.9209L5.56353 3.81277L4.2 2.6L2.6 4.2L3.7627 5.63108L2.898 7.75704L1 8.2V9.8L2.92089 10.3244L3.8126 12.4398L2.6 13.8L4.2 15.4L5.63293 14.2322L7.7176 15.0898L8.2 17H9.8L10.2836 15.0906L12.4041 14.2124C12.7575 14.465 13.8 15.4 13.8 15.4L15.4 13.8L14.2127 12.3995L15.0911 10.2784L16.9999 9.78176L17 8.2L15.0979 7.71632Z" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import icon from './mixins'

export default {
  name: 'hippolyte.atom.icon.settings',
  mixins: [icon('20px')]
}
</script>
