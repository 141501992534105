<template>
  <svg v-bind="$props" viewBox="0 0 8 14" :class="iconClassName">
    <path d="M7 1L1 7L7 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>  
</template>

<script>
import icon from './mixins'

export default {
  name: 'hippolyte.atom.icon.back',
  mixins: [icon('15px')]
}
</script>
