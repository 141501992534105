<template>
  <svg v-bind="$props" viewBox="0 0 18 19" :class="iconClassName">
    <path d="M13 10.714C13.5522 10.714 14 10.2662 14 9.71399C14 9.16179 13.5522 8.71399 13 8.71399C12.4478 8.71399 12 9.16179 12 9.71399C12 10.2662 12.4478 10.714 13 10.714Z"/>
    <path d="M9 10.714C9.5522 10.714 10 10.2662 10 9.71399C10 9.16179 9.5522 8.71399 9 8.71399C8.4478 8.71399 8 9.16179 8 9.71399C8 10.2662 8.4478 10.714 9 10.714Z"/>
    <path d="M5 10.714C5.55228 10.714 6 10.2662 6 9.71399C6 9.16179 5.55228 8.71399 5 8.71399C4.44772 8.71399 4 9.16179 4 9.71399C4 10.2662 4.44772 10.714 5 10.714Z"/>
    <path d="M9 17.714C13.4182 17.714 17 14.1322 17 9.71399C17 5.29571 13.4182 1.71399 9 1.71399C4.58172 1.71399 1 5.29571 1 9.71399C1 11.1711 1.38958 12.5373 2.07026 13.714L1.4 17.314L5 16.6438C6.1767 17.3244 7.54288 17.714 9 17.714Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import icon from './mixins'

export default {
  name: 'hippolyte.atom.icon.chat',
  mixins: [icon('15px')]
}
</script>
