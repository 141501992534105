import parseISO from 'date-fns/parseISO'
import formatISO from 'date-fns/formatISO'
import addMinutes from 'date-fns/addMinutes'
import Notify from '@/notify'
import debug from 'debug'
const log = debug('notif')

class PersistDomain {
  constructor (domain, storage = window.localStorage) {
    this.storage = storage
    this.persistDomain = domain
  }

  loadStorage (...keys) {
    keys.forEach((key) => {
      this[key] = this.storage.getItem(this.forgeKey(key))
      if (this[key] !== null) {
        try {
          this[key] = JSON.parse(this[key])
        } catch (err) {
          this[key] = null
        }
      }
    })
  }

  setStorage (key, data) {
    this[key] = data
    this.storage.setItem(this.forgeKey(key), JSON.stringify(data))
    return data
  }

  clean (key) {
    this[key] = null
    this.storage.removeItem(this.forgeKey(key))
  }

  forgeKey (key) {
    return [this.persistDomain, key].join('::')
  }
}

export default class Permission extends PersistDomain {
  constructor (config, localstorage) {
    super('permission', localstorage)
    this.loadStorage('lastPrompted', 'granted', 'countPrompted')
    this.config = config
  }

  shouldPrompt () {
    if (!Notify.support.notif) {
      return
    }
    const ability = Notify.support.notif && Notification?.permission === 'default'
    const later = !this.granted || this.granted === 'later'
    const since = addMinutes(new Date(), -this.promptInterval() * 60)
    const delay = this.lastPrompted ? (this.promptInterval() && parseISO(this.lastPrompted) < since) : true
    log('Notification %o', Notification?.permission)
    log('granted %o', this.granted)
    log('last prompt %o', parseISO(this.lastPrompted))
    log('interval %o', this.promptInterval())
    log('delay %o', delay)
    log('should prompt %o', ability && later && delay)
    return ability && later && delay
  }

  later () {
    this.setStorage('lastPrompted', formatISO(new Date()))
    this.setStorage('granted', 'later')
    if (!this.countPrompted) {
      this.countPrompted = 0
    }
    this.setStorage('countPrompted', ++this.countPrompted)
  }

  promptInterval () {
    const interval = this.config.prompt?.interval
    if ((!interval || interval.length) && !this.countPrompted) {
      return 0
    }
    return interval?.length > (this.countPrompted || 0) && interval[this.countPrompted - 1]
  }
}
