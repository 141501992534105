const zip = (acc, v) => Object.assign(acc, { [v]: v })
export const StatusSet = ['created', 'started', 'rejected', 'opened', 'sent', 'archived', 'scheduled', 'suggested', 'discarded', 'waiting_acceptation', 'declined', 'accepted', 'recruited', 'met', 'do_not_send', 'scheduling_meeting', 'contract_proposal']
export const Status = StatusSet.reduce(zip, {})
export const TransitionsSet = ['schedule', 'send', 'suggest', 'start', 'open', 'archive', 'unarchive', 'reject', 'discard', 'undiscard', 'require_acceptation', 'decline', 'accept', 'recruit', 'meet', 'schedule_meeting']

export const Transitions = TransitionsSet.reduce(zip, {})
export const Labels = {
  [Status.created]: {
    singular: 'Créé',
    plural: 'Créés'
  },
  [Status.suggested]: {
    singular: 'Nouvelle',
    plural: 'Nouvelles'
  },
  [Status.sent]: {
    singular: 'Transmise',
    plural: 'Transmises'
  },
  [Status.opened]: {
    singular: 'Consultée',
    plural: 'Consultées'
  },
  [Status.started]: {
    singular: 'Interview',
    plural: 'Interviews'
  },
  [Status.archived]: {
    singular: 'Archivée',
    plural: 'Archivées'
  },
  [Status.rejected]: {
    singular: 'Non retenue',
    plural: 'Non retenues'
  },
  [Status.discarded]: {
    singular: 'Non retenue',
    plural: 'Non retenues'
  },
  [Status.waiting_acceptation]: {
    singular: 'En attente',
    plural: 'En attente'
  },
  [Status.declined]: {
    singular: 'Refusée',
    plural: 'Refusées'
  },
  [Status.accepted]: {
    singular: 'Nouvelle',
    plural: 'Nouvelles'
  },
  [Status.do_not_send]: {
    singular: 'Non retenue',
    plural: 'Non retenues'
  },
  [Status.scheduling_meeting]: {
    singular: 'Rendez-vous',
    plural: 'Rendez-vous'
  },
  [Status.recruited]: {
    singular: 'Recruté',
    plural: 'Recrutés'
  },
  [Status.met]: {
    singular: 'Rencontre',
    plural: 'Rencontres'
  }
}

export const Colors = {
  [Status.created]: '#000',
  [Status.suggested]: '#000',
  [Status.sent]: '#6b41b9',
  [Status.opened]: '#163ee2',
  [Status.started]: '#45ADA7',
  [Status.archived]: '#FFC857',
  [Status.rejected]: '#000',
  [Status.discarded]: '#000',
  [Status.met]: '#000',
  [Status.recruited]: '#f48767'
}

const AllowedRoles = {
  ADMIN: {
    [Transitions.suggest]: {
      from: [Status.created]
    },
    [Transitions.discard]: {
      from: [Status.suggested]
    },
    [Transitions.schedule]: {
      from: [Status.created, Status.suggested]
    },
    [Transitions.send]: {
      from: [Status.scheduled, Status.created, Status.suggested]
    },
    [Transitions.reject]: {
      from: [Status.sent, Status.opened, Status.started]
    },
    [Transitions.open]: {
      from: [Status.sent]
    },
    [Transitions.start]: {
      from: [Status.sent, Status.opened]
    },
    [Transitions.archive]: {
      from: [Status.sent, Status.opened, Status.started, Status.met, Status.scheduling_meeting]
    },
    [Transitions.unarchive]: {
      from: [Status.archived, Status.rejected]
    },
    [Transitions.undiscard]: {
      from: [Status.discarded]
    },
    [Transitions.require_acceptation]: {
      from: [Status.created, Status.suggested]
    },
    [Transitions.decline]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.accept]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.recruit]: {
      from: [Status.sent, Status.opened, Status.started, Status.met]
    },
    [Transitions.meet]: {
      from: [Status.sent, Status.opened, Status.started]
    }
  },
  AUTHENTICATED: {
    [Transitions.suggest]: {
      from: []
    },
    [Transitions.discard]: {
      from: []
    },
    [Transitions.schedule]: {
      from: []
    },
    [Transitions.send]: {
      from: []
    },
    [Transitions.reject]: {
      from: [Status.sent, Status.opened, Status.started]
    },
    [Transitions.open]: {
      from: [Status.sent]
    },
    [Transitions.start]: {
      from: [Status.sent, Status.opened]
    },
    [Transitions.archive]: {
      from: [Status.sent, Status.opened, Status.started, Status.met, Status.scheduling_meeting]
    },
    [Transitions.unarchive]: {
      from: [Status.archived]
    },
    [Transitions.undiscard]: {
      from: []
    },
    [Transitions.require_acceptation]: {
      from: []
    },
    [Transitions.decline]: {
      from: []
    },
    [Transitions.accept]: {
      from: []
    },
    [Transitions.recruit]: {
      from: [Status.sent, Status.opened, Status.started, Status.met]
    },
    [Transitions.meet]: {
      from: [Status.sent, Status.opened, Status.started]
    }
  },
  USER: {
    [Transitions.suggest]: {
      from: []
    },
    [Transitions.discard]: {
      from: []
    },
    [Transitions.schedule]: {
      from: []
    },
    [Transitions.send]: {
      from: []
    },
    [Transitions.reject]: {
      from: []
    },
    [Transitions.open]: {
      from: []
    },
    [Transitions.start]: {
      from: []
    },
    [Transitions.archive]: {
      from: []
    },
    [Transitions.unarchive]: {
      from: []
    },
    [Transitions.undiscard]: {
      from: []
    },
    [Transitions.require_acceptation]: {
      from: []
    },
    [Transitions.decline]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.accept]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.meet]: {
      from: []
    },
    [Transitions.recruit]: {
      from: []
    }

  }
}
export function allowed (status, transition, auth) {
  return StatusSet.includes(status) &&
    TransitionsSet.includes(transition) &&
    auth?.user?.roles?.some(role => AllowedRoles[role][transition].from.includes(status))
}
