import { register } from 'register-service-worker'
import debug from 'debug'
const log = debug('sw:register')

let me
export default function () {
  if (!me) {
    me = new Promise(function (resolve, reject) {
      if (import.meta.env.PROD) {
        if (!('serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window)) {
          reject(new Error('unsuported browser'))
        }
        register('/sw.js', {
          ready (sw) {
            log('registred sw.js')
            resolve(sw)
          },
          registered () {
            log('Service worker has been registered.')
          },
          cached () {
            log('Content has been cached for offline use.')
          },
          updatefound () {
            log('New content is downloading.')
          },
          updated () {
            log('New content is available; please refresh.')
          },
          offline () {
            log('No internet connection found. App is running in offline mode.')
          },
          error (error) {
            log('Error during service worker registration:', error)
          }
        })
      } else {
        reject(new Error('not in a production build'))
      }
    })
  }
  return me
}
