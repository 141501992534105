<template>
  <modal media class="confirm-modal" @close="$emit('close')">
    <template v-slot:header>
      <h3>{{ $t('modal.title') }}</h3>
    </template>
    <template v-slot:body>
      <p>{{ $t('modal.body',{ firstname: account.firstname, lastname: account.lastname}) }}</p>
    </template>
    <template v-slot:footer>
      <button class="default" @click="$emit('close')">{{ $t('modal.action_cancel') }}</button>
      <button class="secondary" @click="$emit('confirm')">{{ $t('modal.action_confirm')}}</button>
    </template>
  </modal>
</template>
<script>
import Modal from '@/atoms/modal'

export default {
  name: 'hippolyte.molecules.chat.confirm',
  components: {
    Modal
  },
  props: {
    account: Object
  },
  emits: ['close', 'confirm']
}
</script>
