import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/templates/layout'
import Permission from '@/notify/permission'
import { gtag } from '@/analityc'
const Chat = () => import('@/pages/chat')
const Login = () => import('@/pages/login')
const Settings = () => import('@/pages/settings')
const Apply = () => import('@/pages/apply')
const Dashboard = () => import('@/pages/dashboard')
const Offers = () => import('@/organisms/offers')
const Applications = () => import('@/organisms/applications')
const ProfileEdit = () => import('@/molecules/candidate.edit')
const NotifsEdit = () => import('@/molecules/notifs.edit')
const List = () => import('@/pages/jobList')
const Insights = () => import('@/pages/insights')
const Reachable = () => import('@/pages/reachable')
const ScreenAiSurvey = () => import('@/pages/screenAiSurvey')

function run (context) {
  const routes = [
    {
      path: '/jobs',
      name: 'jobs',
      component: List,
      meta: {
        public: true
      },
      props: function (route) {
        return {
          r: route.query.recruiter,
          postcode: route.query.postcode,
          cv: route.query.cv,
          source: route.query.source,
          sector: route.query.sector
        }
      }
    },
    {
      path: '/insights/:id',
      name: 'insights',
      component: Insights,
      meta: {
        public: true
      },
      props: function (route) {
        return {
          id: route.params.id
        }
      }
    },
    {
      path: '/survey/screen_ai',
      component: ScreenAiSurvey,
      name: 'screenAiSurvey',
      props: function (route) {
        return {
          conciliation: route.query.conciliation,
        }
      }
    },
    {
      path: '/login',
      component: Login,
      name: 'login',
      meta: {
        public: true
      }
    },
    {
      path: '/',
      component: Layout,
      props: function () {
        return {
          permission: new Permission(context.config.notif)
        }
      },
      children: [
        {
          path: '/reachable/:id',
          components: {
            main: Reachable
          },
          name: 'reachable',
          props: function (route) {
            return {
              reachableId: route.params.id,
            }
          }
        },
        {
          path: '/interviews',
          name: 'interviews',
          components: {
            main: Chat
          },
          meta: {
            back: 'dashboard',
            show: { menu: true, conversation: false }
          },
          props: {
            main () {
              context.loader.chat.then(() => {
                if (context.chat.rooms.length && !context.chat.current) {
                  context.chat.join(context.chat.rooms[0])
                }
              })
              return { chat: context.chat }
            }
          }
        },
        {
          path: '/interview/:account',
          name: 'interview',
          components: {
            main: Chat
          },
          meta: {
            back: 'interviews',
            show: {
              menu: false,
              conversation: true
            }
          },
          props: {
            main (route) {
              context.loader.chat.then(() => {
                const room = context.chat.rooms.find(r => r.conciliation.account.id === route.params.account)
                if (room) {
                  context.chat.join(room)
                }
              })
              return { chat: context.chat }
            }
          }
        },
        {
          path: '/settings',
          name: 'settings',
          redirect: '/profile',
          components: {
            main: Settings
          },
          meta: {
            scroll: true
          },
          children: [
            {
              path: '/profile',
              name: 'profile',
              components: {
                settings: ProfileEdit
              }
            },
            {
              path: '/notifications',
              name: 'notifications',
              components: {
                settings: NotifsEdit
              }
            },
          ]
        },
        {
          path: '/apply',
          name: 'apply',
          alias: 'apply',
          meta: {
            scroll: true
          },
          components: {
            main: Apply
          },
          children: [
            {
              path: '/offers',
              name: 'offers',
              components: {
                apply: Offers
              },
              props: {
                apply: {
                  empty: 'empty.ads'
                }
              }
            },
            {
              path: '/applications',
              name: 'applications',
              components: {
                apply: Applications
              },
              props: {
                apply: {
                  empty: 'empty.application.current'
                }
              }
            },
            {
              path: '/archives',
              name: 'archives',
              components: {
                apply: Applications
              },
              props: {
                apply: {
                  empty: 'empty.application.archived'
                }
              }
            }
          ]
        },
        {
          path: '',
          name: 'dashboard',
          alias: 'dashboard',
          meta: {
            scroll: true
          },
          components: {
            main: Dashboard
          }
        },
        {
          path: '/:catchAll(.*)',
          redirect: () => context.isLogged() ? { to: location.hash.slice(1) } : { name: 'login' }
        }
      ]
    }
  ]
  const router = createRouter({
    history: createWebHashHistory(),
    routes
  })

  // eslint-disable-next-line
  router.beforeEach((to, from) => {
    return context.isLogged() || ('public' in to.meta && to.meta.public)
  })

  // eslint-disable-next-line
  router.afterEach((to, from) => {
    gtag('event', 'page_view', {
      page_path: to.path,
      page_location: window.location.toString()
    })
  })
  return router
}

export default run
