<template>
  <svg v-bind="$props" viewBox="0 0 18 19">
    <path d="M1 5.6251V2.24732C1 1.95277 1.23878 1.71399 1.53333 1.71399H6.68889C6.98344 1.71399 7.22222 1.95277 7.22222 2.24732V5.6251C7.22222 5.91965 6.98344 6.15843 6.68889 6.15843H1.53333C1.23878 6.15843 1 5.91965 1 5.6251Z" stroke-width="1.5"/>
    <path d="M10.7778 17.1806V13.8029C10.7778 13.5083 11.0166 13.2695 11.3112 13.2695H16.4667C16.7613 13.2695 17.0001 13.5083 17.0001 13.8029V17.1806C17.0001 17.4752 16.7613 17.714 16.4667 17.714H11.3112C11.0166 17.714 10.7778 17.4752 10.7778 17.1806Z" stroke-width="1.5"/>
    <path d="M10.7778 10.0695V2.24732C10.7778 1.95277 11.0166 1.71399 11.3112 1.71399H16.4667C16.7613 1.71399 17.0001 1.95277 17.0001 2.24732V10.0695C17.0001 10.3641 16.7613 10.6029 16.4667 10.6029H11.3112C11.0166 10.6029 10.7778 10.3641 10.7778 10.0695Z" stroke-width="1.5"/>
    <path d="M1 17.1806V9.35841C1 9.06383 1.23878 8.82507 1.53333 8.82507H6.68889C6.98344 8.82507 7.22222 9.06383 7.22222 9.35841V17.1806C7.22222 17.4752 6.98344 17.714 6.68889 17.714H1.53333C1.23878 17.714 1 17.4752 1 17.1806Z" stroke-width="1.5"/>
  </svg>
</template>

<script>
import icon from './mixins'

export default {
  name: 'hippolyte.atom.icon.dashboard',
  mixins: [icon('20px')]
}
</script>
