import Entity from './entity'
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`
const cache = new Map()

class Trade extends Entity {
  static async search (field, value, socket) {
    const list = await socket.service('entity.Trade/QUERY', {
      alias: 't',
      class: 'Trade',
      limit: 10,
      parameters: [
        { type: cond('Parameter'), name: field, value: `%${value.toLowerCase()}%` }
      ],
      conditions: [
        {
          type: cond('Like'),
          value: field,
          subject: {
            type: cond('Field'),
            name: `LOWER(t.${field})`
          }
        }
      ]
    })
    return list.map(l => new Trade(l, socket))
  }

  async load () {
    if (cache.has(this.id)) {
      this.setData(cache.get(this.id))
    } else {
      await super.load()

      cache.set(this.id, this.marshall())
    }
    return this
  }
}

Trade.prototype.entityClass = 'Trade'
Trade.prototype.entityFields = ['id', 'name']

export default Trade
