<template>
  <svg v-bind="$props" viewBox="0 0 18 17">
    <path d="M5.8 4.2H2.6C2.17565 4.2 1.76869 4.36857 1.46863 4.66863C1.16857 4.96869 1 5.37565 1 5.8V13.8C1 14.2243 1.16857 14.6313 1.46863 14.9314C1.76869 15.2314 2.17565 15.4 2.6 15.4H15.4C15.8243 15.4 16.2313 15.2314 16.5314 14.9314C16.8314 14.6313 17 14.2243 17 13.8V5.8C17 5.37565 16.8314 4.96869 16.5314 4.66863C16.2313 4.36857 15.8243 4.2 15.4 4.2H12.2M5.8 4.2V1.48C5.8 1.3527 5.85057 1.23061 5.94059 1.14059C6.03061 1.05057 6.1527 1 6.28 1H11.72C11.8473 1 11.9694 1.05057 12.0594 1.14059C12.1494 1.23061 12.2 1.3527 12.2 1.48V4.2M5.8 4.2H12.2" stroke-width="1.5"/>
  </svg>
</template>

<script>
import icon from './mixins'

export default {
  name: 'hippolyte.atom.icon.apply',
  mixins: [icon('20px')]
}
</script>
