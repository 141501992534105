import Api from '@/entities/api'
import pick from 'ramda/src/pick'
import { EventEmitter } from 'eventemitter3'
import Location from '@/entities/location'
const service = verb => `entity.Recruiter/${verb}`
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`

const fields = ['id', 'avatar', 'company', 'siret', 'email', 'firstname', 'lastname', 'chatUser', 'gender', 'city', 'paying', 'offer', 'candidate', 'location', 'phone', 'plan', 'stripeId', 'subscribeUntil', 'freeProfiles']
const blank = fields.reduce((acc, val) => Object.assign(acc, { [val]: null }), {})
blank.paying = true

function normalize (value) {
  return value
}

export default class Recruiter extends EventEmitter {
  constructor (data, socket) {
    super()
    this.custom = {}
    this.setData(Object.assign({}, blank, data))
    this.socket = socket
    this.loading = false
  }

  static api (socket) {
    return new Api(socket, 'recruiter')
  }

  static create (data, socket) {
    return new Recruiter(data, socket)
  }

  setData (...args) {
    const data = Object.assign(...args)
    const delta = this.getDelta()
    const surcharge = {}
    if (data.location) {
      if (typeof data.location === 'string') {
        surcharge.location = new Location({ id: data.location }, this.socket)
      } else if (typeof data.location === 'object') {
        surcharge.location = new Location(data.location, this.socket)
      }
    }
    Object.assign(this, data, surcharge)
    this.hasUpdate(delta)
    return this
  }

  getDelta (opts) {
    return JSON.stringify(this.marshall(opts))
  }

  hasUpdate (delta) {
    if (delta !== this.getDelta()) {
      this.emit('update')
    }
  }

  load () {
    if (!this.loading && this.id) {
      this.setData({ loading: this.socket.service(service('READ'), { id: this.id }) })
        .then(normalize)
        .then(data => this.setData(data, { loading: false }))
    }
    return this.loading
  }

  save (serializer) {
    if (!this.saving) {
      const s = this.id ? service('SAVE') : 'recruiter.signin/CREATE'
      this.setData({ saving: this.socket.service(s, serializer ? serializer() : this.marshall()) })
      //  .then(normalize)
      //  .then(data => this.setData(data, { saving: false }))
    }
    return this.saving
  }

  subscribe (args) {
    if (!this.saving) {
      this.setData({ saving: this.socket.service(service('SUBSCRIBE'), args) })
        .then(normalize)
        .then(data => this.setData(data, { saving: false }))
    }
    return this.saving
  }

  marshall (f = fields) {
    const data = pick(f, this)
    if (data.location instanceof Location) {
      data.location = data.location.marshall()
    }
    return data
  }

  watch () {}
}

Recruiter.getByChatUser = async function (chatUser, socket) {
  const list = await socket.service(service('QUERY'), {
    alias: 'r',
    class: 'Recruiter',
    parameters: [
      { type: cond('Parameter'), name: 'chatUser', value: chatUser }
    ],
    conditions: [
      {
        type: cond('Equals'),
        value: 'chatUser',
        subject: {
          type: cond('Field'),
          name: 'r.chatUser'
        }
      }
    ]
  })
  if (list.length === 1) {
    return new Recruiter(list[0], socket)
  }
}

Recruiter.loadIds = async function (recruiters, socket, cancel) {
  if (recruiters.length < 1) {
    return
  }
  try {
    const list = await socket.service(service('QUERY'), {
      alias: 'r',
      class: 'Recruiter',
      parameters: [
        { type: cond('Parameter'), name: 'id', value: recruiters.map(c => c.id) }
      ],
      conditions: [
        {
          type: cond('In'),
          value: 'id',
          subject: {
            type: cond('Field'),
            name: 'r.id'
          }
        }
      ]
    }, { cancel })
    list.forEach(c => {
      recruiters.filter(i => i.id === c.id).map(c2 => c2.setData(normalize(c), { loading: false }))
    })
  } catch (err) {
    recruiters.forEach(function (c) {
      c.setData({ loading: false })
    })
  }
}
Recruiter.search = async function (opts = {}, socket) {
  const search = Object.entries(opts).reduce((opts, [field, value]) => {
    return Object.assign({}, opts, {
      parameters: opts.parameters.concat([{
        type: cond('Parameter'),
        name: field,
        value: `%${value.toLowerCase()}%`
      }]),
      conditions: opts.conditions.concat([{
        type: cond('Like'),
        value: field,
        subject: {
          type: cond('Field'),
          name: `LOWER(r.${field})`
        }
      }])
    })
  }, {
    alias: 'r',
    class: 'Recruiter',
    limit: 10,
    parameters: [],
    conditions: []
  })
  const list = await socket.service(service('QUERY'), search)
  return list.map(l => new Recruiter(l, socket))
}
