import pick from 'ramda/src/pick'
import { EventEmitter } from 'eventemitter3'
import { normalize, blank, props } from './normalize.entity.js'
import { factory } from '@/fun.js'

const service = verb => `entity.Candidate/${verb}`

const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`

export default class Candidate extends EventEmitter {
  constructor (data, socket) {
    super()
    this.setData(blank, data)
    this.socket = socket
    this.loading = false
    this.savingField = {}
  }

  setData (...data) {
    Object.assign(this, ...data)
    this.emit('update', this)
    return this
  }

  static create (data, socket) {
    return factory(Candidate, data, socket)
  }

  load () {
    if (!this.loading && this.id) {
      if (this.loaded) {
        return Promise.resolve(this)
      }
      this.setData({ loading: this.socket.service(service('READ'), { id: this.id }) })
      this.loading
        .then(normalize)
        .then(data => this.setData(data, { loading: false, loaded: true }))
        .then(() => this)
    }
    return this.loading
  }

  saveField (field, value) {
    if (!this.savingField[field.base_name] && this.id) {
      const saving = this.socket.service(service('EDIT_FIELD'), {
        id: this.id,
        value: value,
        field: field.base_name
      })
      this.savingField[field.base_name] = saving
      this.setData({})
      saving
        .then( this.setData({ [field.base_name]: value }))
        .then(() => Object.assign(this.savingField, { [field.base_name]: null }))
        .then(() => this)
    }
    return this.savingField[field.base_name]
  }

  marshall (opts = props) {
    return pick(opts, this)
  }

  reset () {
    return this.setData(blank)
  }

  watch () {}
}

Candidate.props = props

Candidate.getByChatUser = async function (chatUser, socket) {
  const list = await socket.service(service('QUERY'), {
    alias: 'r',
    class: 'Candidate',
    parameters: [
      { type: cond('Parameter'), name: 'chatUser', value: chatUser }
    ],
    conditions: [
      {
        type: cond('Equals'),
        value: 'chatUser',
        subject: {
          type: cond('Field'),
          name: 'r.chatUser'
        }
      }
    ]
  })
  if (list.length === 1) {
    const c = Candidate.create(list[0], socket)
    c.setData({ loaded: true })
    return c
  }
}

Candidate.loadBy = async function (field, candidates, socket, cancel) {
  candidates = candidates.filter(c => c && !c.loaded)
  if (candidates.length < 1) {
    return
  }
  let batch = Promise.resolve()
  const chunk = 10
  for (let i = 0; i < candidates.length; i += chunk) {
    const { promise, run } = loadBatch(field, candidates.slice(i, i + chunk), socket, cancel)
    batch.then(run)
    batch = promise
  }
  return batch
}
function loadBatch (field, candidates, socket, cancel) {
  let run
  const promise = new Promise(function (resolve, reject) {
    run = async function () {
      try {
        const list = await socket.service(service('QUERY'), {
          alias: 'r',
          class: 'Candidate',
          parameters: [
            { type: cond('Parameter'), name: field, value: candidates.map(c => c[field]) }
          ],
          conditions: [
            {
              type: cond('In'),
              value: field,
              subject: {
                type: cond('Field'),
                name: `r.${field}`
              }
            }
          ]
        }, { cancel })
        list.forEach(c => {
          candidates.filter(i => i[field] === c[field]).map(c2 => {
            c2.setData(normalize(c), { loading: false, loaded: true })
            return c2
          })
        })
        resolve(candidates)
      } catch (err) {
        reject(err)
        candidates.forEach(c => {
          c.setData({ loading: false })
        })
      }
    }
  })
  candidates.forEach(c => {
    c.setData({ loading: promise.then(() => c) })
  })
  return { promise, run }
}

Candidate.loadIds = function (...args) {
  return Candidate.loadBy('id', ...args)
}

Candidate.loadChatUsers = function (...args) {
  return Candidate.loadBy('chatUser', ...args)
}
