import parseISO from 'date-fns/parseISO'

const props = ['archivedAt', 'avatar', 'chatUser', 'createdAt', 'email', 'firstname', 'gender', 'hideAvatar', 'id', 'internalComment', 'lastname', 'location', 'manyChatId', 'optedInThrough', 'phone', 'reviewedAt', 'custom', 'coordinates']
const blank = props.reduce((acc, val) => Object.assign(acc, { [val]: null }), {})
blank.custom = {}

function toCamel (txt) {
  const t = txt.split('_')
    .map(w => w[0].toUpperCase() + w.slice(1))
    .join('')
  return t[0].toLowerCase() + t.slice(1)
}
function onCustomCandidate (field, k, v, to, acc) {
  if ((k === 'custom_field_candidat_' + field) && props.includes(to) && !acc[to]) {
    acc[to] = v
    return true
  }
}
function onSystem (field, k, v, to, acc) {
  if (k === field && props.includes(to)) {
    acc[to] = v
    return true
  }
}
function onTrade (k, v, acc) {
  if (k === 'custom_field_candidat_trade') {
    if (v && v.length) {
      const splited = v.split(':')
      if (splited.length > 1) {
        acc.custom.candidatTradeId = splited.shift()
      }
      if (splited.length > 1) {
        acc.custom.candidatTradeTrust = Number(splited.shift())
      }
      if (splited.length) {
        acc.custom.candidatTrade = splited.pop()
      }
    }
    return true
  }
}
function onCreatedAt (k, v, acc) {
  if (k === 'createdAt') {
    try {
      acc.createdAt = parseISO(v)
    } catch {
      acc.createdAt = null
    }
    return true
  }
}
function normalize (value) {
  const r = Object.entries(value).reduce((acc, [k, v]) => {
    if (v === null || typeof v === 'undefined') {
      return acc
    }
    const done = onCreatedAt(k, v, acc) ||
      onCustomCandidate('mail', k, v, 'email', acc) ||
      onCustomCandidate('lastname', k, v, 'lastname', acc) ||
      onCustomCandidate('firstname', k, v, 'firstname', acc) ||
      onCustomCandidate('avatar', k, v, 'avatar', acc) ||
      onCustomCandidate('location_string', k, v, 'location', acc) ||
      onCustomCandidate('phone', k, v, 'phone', acc) ||
      onCustomCandidate('location_coordinates', k, v, 'coordinates', acc) ||
      onTrade(k, v, acc) ||
      onSystem('first_name', k, v, 'firstname', acc) ||
      onSystem('last_name', k, v, 'lastname', acc) ||
      onSystem('profile_pic', k, v, 'avatar', acc) ||
      onSystem(k, k, v, toCamel(k), acc)
    if (done) {
      return acc
    }
    const c = k.match(/^custom_field_([A-Za-z0-9_]*)$/)
    if (c && c.length === 2) {
      acc.custom[toCamel(c[1])] = v
      return acc
    }
    return acc
  }, { custom: {} })

  return Object.assign({}, blank, r)
}
export {
  props, blank, normalize
}
