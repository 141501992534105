<template>
  <svg v-bind="$props" viewBox="0 0 222.41 222.41">
    <path class="e" d="m68.51,93.31c4.55-3.12,6.93-5.44,12.14-6.16,5.85-.81,12.6-.11,18.51-.01.55,0,1.09.05,1.63.1,1.73-5.14,4-10.21,8.02-13.32,2.4-1.85,3.78-2.28,8.4-1.17,7.34,1.76,14.99,2.86,22.19,5.25,2.14.71,4.24,1.33,6.3,2.05-2.55-9.9-8.25-18.91-16.42-25.26-5.44-4.23-11.85-7.19-18.62-8.51-7.39-1.44-10.85,3.24-18.11,4.34-6.69,1.01-14.14,2.63-20.76,4.08-6.82,1.5-11.74,7.21-14.8,13.22-4.71,9.25-2.94,23.17,1,34.84,3.27-3.58,7.11-7.11,10.52-9.46"/>
    <path class="g" d="m174.46,99.81c-.36-.73-1.98-2.69-2.55-3.31-2.97-3.56-16.43-11.72-19.21-13.24-2.78-1.53-4.59-2.37-7-3.21.99,3.85,1.51,7.82,1.51,11.84,0,16.45-8.29,30.91-20.79,39.15,0,.19,0,.39,0,.58-.12,8.07-2.59,15.9-7.01,22.48,5.27,1.67,10.68,2.86,16.23,2.3,8.01-.8,13.39-8.12,19.43-12.9,6.76-5.36,13.37-11.08,17.85-18.56,3.92-6.56,5.1-17.97,1.57-25.11"/>
    <path class="f" d="m117.2,72.75c-4.63-1.11-6-.78-8.41,1.17-4.02,3.11-6.29,8.18-8.02,13.32,9.23.95,17.74,8.49,22.1,16.58,4.36,8.08,3.68,18.37,3.52,27.21,12.5-8.24,20.79-22.69,20.79-39.15,0-4.01-.51-7.99-1.51-11.84-2.05-.71-4.16-1.34-6.3-2.05-7.21-2.39-14.85-3.49-22.19-5.25"/>
    <path class="h" d="m126.41,131.03c-6.97,4.59-15.24,7.25-24.12,7.25-4.81,0-9.51-.82-13.94-2.34-10.08-3.44-18.82-10.49-24.36-19.83-2.52-4.23-3.95-8.83-6.01-13.24-6.24,5.52-2.19,13.27-5.55,20.18-1,2.06-2.64,3.88-3.59,6.04-2.44,5.56-3.04,10.67-2.99,16.64.03,3.61.6,6.97,1.79,10.31,1.97,5.49,3.21,8.6,8.13,12.11,4.6,3.28,7.19,5.77,12.92,7.11,4.44,1.04,9.13,1.7,13.58.45,9.54-2.68,21.13-6.45,28.9-12.67,3.21-2.57,5.97-5.6,8.22-8.96,4.42-6.58,6.89-14.41,7.01-22.48,0-.19,0-.39.01-.58"/>
    <path class="i" d="m122.88,103.82c-4.36-8.09-12.87-15.63-22.1-16.58-.54-.06-1.08-.09-1.63-.1-5.91-.1-12.66-.8-18.51.01-5.21.72-7.58,3.04-12.14,6.16-3.42,2.34-7.25,5.87-10.52,9.46,1.71,5.06,3.82,9.7,5.99,13.36,5.55,9.33,14.28,16.38,24.36,19.83,4.44,1.52,9.14,2.34,13.94,2.34,8.88,0,17.15-2.66,24.12-7.25.16-8.84.83-19.13-3.52-27.21"/>
</svg>
</template>

<script>
import icon from './icons/mixins'
export default {
  name: 'hippolyte.atom.logo',
  mixins: [icon('100%')]
}
</script>

<style lang="stylus" scoped>
  @import '../colors'
  .e
    fill $color-banana
  .f
    fill $color-peach-light
  .g
    fill $color-peach
  .h
    fill $color-azur
  .i
    fill $color-iceberg
</style>
