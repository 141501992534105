import Entity from '@/entities/entity'
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`

class Location extends Entity {
  static async search (opts, socket) {
    const iso = opts.iso || 'FR'
    const value = opts.value?.toLowerCase() || ''
    const list = await socket.service('entity.Location/QUERY', {
      alias: 'l',
      class: Location.prototype.entityClass,
      limit: 10,
      order_by: [
        {
          field: {
            type: 'YesWeChat\\ServiceEntityBundle\\Query\\Condition\\Field',
            name: 'length(l.name)'
          },
          direction: 'ASC'
        },
        {
          field: {
            type: 'YesWeChat\\ServiceEntityBundle\\Query\\Condition\\Field',
            name: 'l.name'
          },
          direction: 'ASC'
        },
        {
          field: {
            type: 'YesWeChat\\ServiceEntityBundle\\Query\\Condition\\Field',
            name: 'l.zip'
          },
          direction: 'ASC'
        }
      ],
      parameters: [
        { type: cond('Parameter'), name: 'name', value: `%${value}%` },
        { type: cond('Parameter'), name: 'iso', value: `${iso.toUpperCase()}` }
      ],
      conditions: [
        {
          type: cond('OrX'),
          conditions: [
            {
              type: cond('Like'),
              value: 'name',
              subject: {
                type: cond('Field'),
                name: 'lower(l.name)'
              }
            },
            {
              type: cond('Like'),
              value: 'name',
              subject: {
                type: cond('Field'),
                name: 'lower(l.location)'
              }
            },
            {
              type: cond('Like'),
              value: 'name',
              subject: {
                type: cond('Field'),
                name: 'lower(l.city)'
              }
            }
          ]
        },
        {
          type: cond('Equals'),
          value: 'iso',
          subject: {
            type: cond('Field'),
            name: 'l.iso'
          }
        }
      ]
    })
    return list.map(l => new Location(l, socket))
  }
}

Location.prototype.entityClass = 'Location'
Location.prototype.entityFields = ['id', 'name']

export default Location
