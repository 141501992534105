import pick from 'ramda/src/pick'
import { EventEmitter } from 'eventemitter3'
import Recruiter from '@/entities/recruiter'
const service = verb => `entity.RecruiterAccount/${verb}`
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`

const fields = ['id', 'avatar', 'email', 'firstname', 'lastname', 'chatUser', 'gender', 'phone', 'role', 'searches']
const blank = fields.reduce((acc, val) => Object.assign(acc, { [val]: null }), {})

function updateModelField (current, next, Model) {
  if (typeof current === 'undefined' || current === null) {
    if (typeof next === 'undefined' || next === null || next instanceof Model) {
      return next
    }
    if (typeof next === 'object') {
      return Model.create(next)
    }
    if (typeof next === 'string') {
      return Model.create({ id: next })
    }
  }
  if (typeof next === 'undefined') {
    return current
  }
  if (next === null) {
    return next
  }
  if (typeof next === 'object') {
    current.setData(next)
    return current
  }
  if (typeof next === 'string') {
    return current.id === next ? current : Model.create({ id: next })
  }
  return next
}

export default class Account extends EventEmitter {
  constructor (data, socket) {
    super()
    this.setData(Object.assign({}, blank, data))
    this.socket = socket
    this.loading = false
  }

  static create (data, socket) {
    return new Account(data, socket)
  }

  setData (...args) {
    const delta = this.getDelta()
    const data = Object.assign(...args)
    data.recruiter = updateModelField(this.recruiter, data.recruiter, Recruiter)
    Object.assign(this, data)
    this.hasUpdate(delta)
    return this
  }

  getDelta (opts) {
    return JSON.stringify(this.marshall(opts))
  }

  hasUpdate (delta) {
    if (delta !== this.getDelta()) {
      this.emit('update')
    }
  }

  load () {
    if (!this.loading && this.id) {
      this.setData({ loading: this.socket.service(service('READ'), { id: this.id }) })
        .then(data => this.setData(data, { loading: false }))
    }
    return this.loading
  }

  save (serializer) {
    if (!this.saving) {
      const s = this.id ? service('SAVE') : 'recruiter.signin/CREATE'
      this.setData({ saving: this.socket.service(s, serializer ? serializer() : this.marshall()) })
    }
    return this.saving
  }

  subscribe (args) {
    if (!this.saving) {
      this.setData({ saving: this.socket.service(service('SUBSCRIBE'), args) })
        .then(data => this.setData(data, { saving: false }))
    }
    return this.saving
  }

  marshall (f = fields) {
    const data = pick(f, this)
    return data
  }

  watch () {}
}

Account.getByChatUser = async function (chatUser, socket) {
  const list = await socket.service(service('QUERY'), {
    alias: 'r',
    class: 'RecruiterAccount',
    parameters: [
      { type: cond('Parameter'), name: 'chatUser', value: chatUser }
    ],
    conditions: [
      {
        type: cond('Equals'),
        value: 'chatUser',
        subject: {
          type: cond('Field'),
          name: 'r.chatUser'
        }
      }
    ]
  })
  if (list.length === 1) {
    return new Account(list[0], socket)
  }
}

Account.loadIds = async function (recruiters, socket, cancel) {
  if (recruiters.length < 1) {
    return
  }
  try {
    const list = await socket.service(service('QUERY'), {
      alias: 'r',
      class: 'RecruiterAccount',
      parameters: [
        { type: cond('Parameter'), name: 'id', value: recruiters.map(c => c.id) }
      ],
      conditions: [
        {
          type: cond('In'),
          value: 'id',
          subject: {
            type: cond('Field'),
            name: 'r.id'
          }
        }
      ]
    }, { cancel })
    list.forEach(c => {
      recruiters.filter(i => i.id === c.id).map((c2) => c2.setData(c, { loading: false }))
    })
  } catch (err) {
    recruiters.forEach(function (c) {
      c.setData({ loading: false })
    })
  }
}
Account.search = async function (opts = {}, socket) {
  const search = Object.entries(opts).reduce((opts, [field, value]) => {
    return Object.assign({}, opts, {
      parameters: opts.parameters.concat([{
        type: cond('Parameter'),
        name: field,
        value: `%${value.toLowerCase()}%`
      }]),
      conditions: opts.conditions.concat([{
        type: cond('Like'),
        value: field,
        subject: {
          type: cond('Field'),
          name: `LOWER(r.${field})`
        }
      }])
    })
  }, {
    alias: 'r',
    class: 'RecruiterAccount',
    limit: 10,
    parameters: [],
    conditions: []
  })
  const list = await socket.service(service('QUERY'), search)
  return list.map(l => new Account(l, socket))
}
