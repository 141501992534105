<template>
  <article class="empty-chat">
    <header v-if="welcome"><h2>{{ $t('welcome_firstname', { firstname: $candidate.firstname }) }}</h2></header>
    <p>{{ $t('empty.messages') }}</p>
    <p class="img-wrapper">
      <img src="/images/empty_chat.svg"/>
    </p>
  </article>
</template>
<script>
export default {
  name: 'hippolyte.atom.chat.empty',
  props: {
    welcome: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../../colors'
@import '../../mixins'
@import '../../atoms/font'
.empty-chat
  width 100%
  background gradient(0deg, $color-iceberg-light, 0.4)
  display flex
  flex-direction column
  align-items center
  justify-content space-evenly
  font-std()
  @media (min-width $bp-mobile)
    font-lg()
  .img-wrapper
    width 95%
    @media (min-width $bp-mobile)
      height 50%
      img
        min-height 100px
        height 100%
  p
    text-align center
    margin 1em
</style>
