<template>
  <div class="candidate-profile_container">
    <div>
      <avatar :candidate="candidate"></avatar>
    </div>
    <div class="candidate-profile_infos">
      <p class="candidate-name">{{ candidate.first_name }} {{ candidate.last_name }}</p>
      <p class="candidate-email">{{ candidate.email }}</p>
    </div>
    <div>
      <router-link :to="{ name: 'settings'}">
        <icon-settings class="candidate-profile_picto" width="16px" height="16px"></icon-settings>
      </router-link>
      <icon-logout @click="logout" class="candidate-profile_picto last" width="16px" height="16px"></icon-logout>
    </div>
  </div>
</template>
<script>
import Candidate from '@/entities/candidate'
import IconSettings from '@/atoms/icons/settings'
import IconLogout from '@/atoms/icons/logout'
import Avatar from '@/atoms/avatar'

export default {
  name: 'hippolyte.molecule.candidate.profile',
  components: {
    IconSettings,
    IconLogout,
    Avatar
  },
  props: {
    candidate: {
      type: Candidate
    }
  },
  provide () {
    return {
      avatarBig: true
    }
  },
  methods: {
    async logout () {
      await this.$auth.logout()
      window.location.replace(window.location.origin)
    }
  }
}
</script>
<style lang="stylus" scoped>
@import '../colors'
@import '../mixins'
@import '../atoms/font'
.candidate-profile_container
  border-radius 20px
  border 1px solid rgba(22, 78, 226, 0.15)
  padding 16px
  display flex
  flex-direction row
  justify-content space-between
  .candidate-profile_infos
    display flex
    flex-direction column
    justify-content center
  .candidate-name
    font-size 18px
    font-weight 600
    line-height 18px
    margin-bottom 5px
    margin-top 0
  .candidate-email
    font-size 12px
    line-height 12px
    margin 0
  .candidate-profile_picto
    fill none
    stroke $color-azur
    &.last
      margin-left 24px
</style>
