<template>
  <svg v-bind="$props" viewBox="0 0 560 195">
    <g>
      <path class="b" d="m55.07,79.66c4.55-3.11,6.93-5.43,12.14-6.14,5.85-.81,12.6-.11,18.52-.01.55,0,1.09.05,1.63.1,1.73-5.13,4-10.18,8.02-13.28,2.4-1.85,3.78-2.27,8.41-1.16,7.35,1.76,14.99,2.85,22.2,5.23,2.14.71,4.24,1.33,6.3,2.04-2.55-9.87-8.25-18.85-16.43-25.18-5.45-4.22-11.86-7.17-18.63-8.48-7.39-1.43-10.86,3.23-18.11,4.33-6.69,1.01-14.15,2.62-20.76,4.07-6.83,1.49-11.74,7.19-14.8,13.18-4.71,9.23-2.95,23.1,1,34.74,3.27-3.57,7.11-7.09,10.53-9.43"/>
      <path class="d" d="m161.07,86.14c-.36-.73-1.98-2.68-2.55-3.3-2.97-3.55-16.44-11.68-19.22-13.2-2.78-1.52-4.59-2.37-7.01-3.2.99,3.83,1.51,7.8,1.51,11.8,0,16.41-8.3,30.82-20.8,39.03,0,.19,0,.39,0,.58-.12,8.05-2.59,15.85-7.01,22.41,5.27,1.66,10.68,2.85,16.24,2.3,8.01-.8,13.4-8.1,19.44-12.86,6.76-5.34,13.38-11.04,17.85-18.51,3.92-6.54,5.1-17.92,1.57-25.04"/>
      <path class="c" d="m103.78,59.16c-4.63-1.11-6.01-.78-8.41,1.16-4.02,3.11-6.29,8.15-8.02,13.28,9.24.95,17.75,8.46,22.11,16.53,4.36,8.06,3.68,18.31,3.52,27.13,12.51-8.22,20.8-22.62,20.8-39.03,0-4-.52-7.97-1.51-11.8-2.06-.71-4.16-1.33-6.3-2.04-7.21-2.39-14.86-3.48-22.2-5.23"/>
      <path class="e" d="m112.99,117.26c-6.97,4.58-15.25,7.23-24.13,7.23-4.81,0-9.51-.82-13.95-2.33-10.08-3.43-18.82-10.46-24.37-19.77-2.52-4.22-3.95-8.8-6.01-13.2-6.24,5.51-2.19,13.23-5.55,20.12-1,2.05-2.64,3.87-3.59,6.02-2.44,5.54-3.04,10.64-2.99,16.59.03,3.6.6,6.95,1.79,10.28,1.97,5.48,3.21,8.58,8.14,12.07,4.6,3.27,7.2,5.76,12.92,7.09,4.44,1.03,9.13,1.7,13.59.44,9.54-2.68,21.13-6.43,28.91-12.63,3.21-2.56,5.97-5.58,8.22-8.93,4.42-6.56,6.89-14.37,7.01-22.41,0-.19,0-.38.01-.58"/>
      <path class="f" d="m109.47,90.14c-4.36-8.07-12.87-15.58-22.11-16.53-.54-.06-1.09-.09-1.63-.1-5.91-.1-12.66-.8-18.52.01-5.21.72-7.59,3.03-12.14,6.14-3.42,2.34-7.25,5.86-10.53,9.43,1.71,5.04,3.82,9.67,5.99,13.32,5.55,9.31,14.29,16.34,24.37,19.77,4.44,1.51,9.14,2.33,13.95,2.33,8.88,0,17.16-2.65,24.13-7.23.16-8.82.83-19.07-3.53-27.13"/>
    </g>
    <g>
      <path class="g" d="m194.24,71.26h10.7v16.64h18.4v-16.64h10.7v42.94h-10.7v-17.94h-18.4v17.94h-10.7v-42.94Z"/>
      <path class="g" d="m239.72,70.68h9.92v7.9h-9.92v-7.9Zm0,11.29h9.92v32.24h-9.92v-32.24Z"/>
      <path class="g" d="m254.99,81.96h9.92l-.2,6.59h.06c1.24-4.44,5.35-7.11,10.9-7.11,9,0,14.88,6.39,14.88,15.92,0,10.44-5.87,17.36-14.94,17.36-5.87,0-9.53-2.41-10.96-6.33h-.07c.13,2.15.33,5.35.33,6.53v12.2h-9.92v-45.16Zm25.71,15.79c0-5.22-3-8.74-7.5-8.74-4.96,0-8.29,3.59-8.29,9.01v1.44c0,4.63,3.33,7.7,8.35,7.7,4.44,0,7.44-3.78,7.44-9.4Z"/>
      <path class="g" d="m295.06,81.96h9.92l-.2,6.59h.07c1.24-4.44,5.35-7.11,10.9-7.11,9.01,0,14.88,6.39,14.88,15.92,0,10.44-5.87,17.36-14.94,17.36-5.87,0-9.53-2.41-10.96-6.33h-.06c.13,2.15.33,5.35.33,6.53v12.2h-9.92v-45.16Zm25.71,15.79c0-5.22-3-8.74-7.5-8.74-4.96,0-8.29,3.59-8.29,9.01v1.44c0,4.63,3.33,7.7,8.35,7.7,4.44,0,7.44-3.78,7.44-9.4Z"/>
      <path class="g" d="m334.41,98.15c0-10.51,6.92-16.71,17.55-16.71s17.55,6.2,17.55,16.71-6.92,16.57-17.55,16.57-17.55-6.13-17.55-16.57Zm25.19,0c0-5.74-2.81-9.14-7.63-9.14s-7.63,3.39-7.63,9.14,2.81,9.01,7.63,9.01,7.63-3.33,7.63-9.01Z"/>
      <path class="g" d="m374.02,68.98h9.92v45.22h-9.92v-45.22Z"/>
      <path class="g" d="m392.68,127.06v-7.37c.98.26,2.48.52,4.11.52,3.26,0,4.76-1.57,6.39-6h-2.15l-13.38-32.24h11.35l8.29,22.19h.13l7.5-22.19h9.53l-11.68,33.02c-3.26,9.27-7.24,12.92-13.9,12.92-3.26,0-5.35-.52-6.2-.85Z"/>
      <path class="g" d="m430.79,103.11v-13.44h-4.76v-7.7h3.85c1.5,0,2.02-.85,2.22-2.81l.46-4.5h8.16v7.31h10.31v7.7h-10.31v12.92c0,3.33,1.57,4.37,5.48,4.37,1.37,0,3.39-.2,4.5-.46v7.5c-.85.2-3.59.72-6.26.72-10.05,0-13.64-4.44-13.64-11.62Z"/>
      <path class="g" d="m486.12,99.97h-22.9c.46,5.29,3.13,7.57,7.57,7.57,3.85,0,6.33-1.37,6.59-4.5h8.74c-.2,7.37-6.33,11.68-15.53,11.68-10.7,0-17.36-6.07-17.36-16.31s6.66-16.97,17.55-16.97c9,0,15.33,5.16,15.33,15.73v2.81Zm-8.74-5.81c0-3.65-2.48-5.55-6.59-5.55s-6.53,1.83-7.31,6.07h13.9v-.52Z"/>
      <path class="g" d="m490.41,86.76h5.63v5.71h-5.63v-5.71Z"/>
      <path class="g" d="m499.66,87.07c0-3.08,2.21-5.33,7.19-5.33h6.12v-1.26c0-2.28-1.29-3.2-3.96-3.2-2.17,0-3.42.91-3.42,2.43,0,.11,0,.42.04.8h-5.33c-.04-.31-.08-.72-.08-1.1,0-3.8,3.35-6.05,9.05-6.05s9.47,2.66,9.47,7.68v11.41h-5.78c.11-.95.27-2.66.27-3.8h-.04c-.57,2.66-2.7,4.11-6.12,4.11-4.37,0-7.42-1.9-7.42-5.71Zm13.31-1.83v-.38h-5.59c-1.56,0-2.51.72-2.51,1.79,0,1.45,1.26,2.21,3.35,2.21,3,0,4.75-1.33,4.75-3.61Z"/>
      <path class="g" d="m521.61,67.1h5.78v4.6h-5.78v-4.6Zm0,6.58h5.78v18.79h-5.78v-18.79Z"/>
    </g>
  </svg>
</template>

<script>
import icon from '@/atoms/icons/mixins'

export default {
  name: 'hippolyte.atom.logo_name',
  mixins: [icon('100%')]
}
</script>

<style lang="stylus" scoped>
@import '../colors'
.b
  fill $color-banana
.c
  fill $color-peach-light
.d
  fill $color-peach
.e
  fill $color-azur
.f
  fill $color-iceberg
.g
  fill $color-darker-grey
</style>
