import parseISO from 'date-fns/parseISO'
import { EventEmitter } from 'eventemitter3'
import pick from 'ramda/src/pick'
import omit from 'ramda/src/omit'
import { Status, Transitions, TransitionsSet } from '@/entities/conciliation_status'
import Recruiter from '@/entities/recruiter'
import Candidate from '@/entities/candidate'
import Search from '@/entities/search'
import { factory, collectIds } from '@/fun.js'
import { Solr, or, eq, search } from '@/entities/solr'
import Api from '@/entities/api'

const service = verb => `entity.Conciliation/${verb}`
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`
function updateIncludes (me, key, Model, data, socket) {
  const aug = {}
  if (data && typeof data[key] !== 'undefined') {
    if (data[key] instanceof Model) {
      aug[key] = data[key]
    } else if (typeof data[key] === 'object') {
      aug[key] = me[key] ? me[key].setData(data[key]) : Model.create(data[key], socket)
    } else if (typeof data[key] === 'string' && !(me[key] && data[key] === me[key].id)) {
      aug[key] = Model.create({ id: data[key] }, socket)
    }
  }
  return aug
}

function normalize (me, data, socket) {
  if (data) {
    const aug = {}
    Object.entries(Conciliation.modelIncludes)
      .reduce((aug, [key, Model]) => Object.assign(aug, updateIncludes(me, key, Model, data, socket)), aug)
    return Object.assign({}, omit(Object.keys(Conciliation.modelIncludes), data), aug)
  }
  return data
}

export default class Conciliation extends EventEmitter {
  constructor (data, socket) {
    super()
    this.domain = '/room/interview'
    this.socket = socket
    this.setData(data)
    this.loading = false
  }

  static create (data, socket) {
    return factory(Conciliation, data, socket)
  }

  watch () {
    if (!this.watching) {
      const refresh = () => {
        this.loaded = false
        return this.load()
      }
      this.watchers = [
        [`/entity/Conciliation/${this.id}`, 'WORKFLOW', refresh],
        [`/entity/Conciliation/${this.id}`, 'ENTITY', refresh]
      ]
      this.watching = Promise.all(this.watchers.map(args => this.socket.sub(...args)))
    }
    return this.watching
  }

  destroy () {
    this.removeAllListeners()
    if (this.watchers) {
      return Promise.all(this.watchers.map(args => this.socket.unsub(...args)))
      // @todo should we purge the factory ?
    }
  }

  static api (socket) {
    return new Api(socket, 'conciliations')
  }
  // eslint-disable-next-line no-console
  static modelIncludes = { recruiter: Recruiter, candidate: Candidate, search: Search }

  async state (transition) {
    if (transition) {
      if (!TransitionsSet.includes(transition)) {
        throw Error('invalid transition name')
      }
      try {
        if (this.updating) {
          throw Error('already updating')
        }
        const updating = this.socket.service(service('WORKFLOW'), { id: this.id, transition })
        this.setData({ updating, doing: transition })
        const interview = await updating
        this.setData(interview, { updating: null, doing: null })
      } catch {
        this.setData({ updating: null, doing: null })
      }
      return this
    }
  }

  setData (...data) {
    const delta = this.getDelta()
    const includes = pick(Object.keys(Conciliation.modelIncludes), this)
    const update = Object.assign(...data.map(d => normalize(this, d, this.socket)))
    if (update.statuses?.docs) {
      update.statuses = update.statuses.docs.map(s => s)
    }
    if (update.statuses) {
      update.statuses.forEach(s => {
        s.date = parseISO(s.date)
        return s
      })
    }
    Object.assign(this, update)
    if (!this.statuses || !Array.isArray(this.statuses)) {
      this.statuses = []
    }
    if (update.statuses && this.statuses.length) {
      this.statuses.sort((a, b) => {
        if (a.order > b.order) {
          return -1
        }
        if (a.order < b.order) {
          return 1
        }

        return 0
      })
      this.status = this.statuses[0].status
      const sent = this.statuses.find(s => s.status === Status.sent)
      this.sentAt = sent?.date
    }
    this.hasUpdate(delta, includes)
    return this
  }

  getDelta () {
    return JSON.stringify(this.marshall())
  }

  hasUpdate (delta, includes = false) {
    if (delta !== this.getDelta()) {
      return this.emit('update', this, JSON.parse(delta))
    }
    if (includes) { // does includes object has change (by refs)
      return Object.keys(Conciliation.modelIncludes)
        .map(k => includes[k] === this[k])
        .includes(false)
    }
  }

  isCreated () {
    return this.status === Status.created
  }

  isScheduled () {
    return this.status === Status.scheduled
  }

  suggest () {
    return this.state(Transitions.suggest)
  }

  isSuggested () {
    return this.status === Status.suggested
  }

  isSuggesting () {
    return this.doing === Transitions.suggest
  }

  canSchedule () {
    return this.isCreated() || this.isSuggested()
  }

  schedule () {
    return this.state(Transitions.schedule)
  }

  isScheduling () {
    return this.doing === Transitions.schedule
  }

  isSending () {
    return this.doing === Transitions.send
  }

  isSent () {
    return this.status === Status.sent
  }

  canSend () {
    return this.isScheduled() || this.isCreated() || this.isSuggested()
  }

  canReject () {
    return this.isSent() || this.isOpened() || this.isStarted() || this.isSchedulingMeeting()
  }

  reject () {
    return this.state(Transitions.reject)
  }

  isRejected () {
    return this.status === Status.rejected
  }

  isRejecting () {
    return this.doing === Transitions.reject
  }

  canRecruit () {
    return this.isSent() || this.isOpened() || this.isStarted() || this.isMet() || this.isSchedulingMeeting()
  }

  recruit () {
    return this.state(Transitions.recruit)
  }

  isRecruited () {
    return this.status === Status.recruited
  }

  isRecruting () {
    return this.doing === Transitions.recruit
  }

  canDiscard () {
    return this.isSuggested()
  }

  discard () {
    return this.state(Transitions.discard)
  }

  isDiscarded () {
    return this.status === Status.discarded
  }

  isDiscarding () {
    return this.doing === Transitions.discard
  }

  undiscard () {
    return this.state(Transitions.undiscard)
  }

  isUndiscarding () {
    return this.doing === Transitions.undiscard
  }

  async start () {
    if (!this.canStart()) { throw Error('invalid transition state') }
    if (this.isSent()) {
      await this.open()
    }
    if (this.isOpened()) {
      return this.state(Transitions.start)
    }
    if (this.isArchived()) {
      return this.state(Transitions.unarchive)
    }
  }

  canStart () {
    return this.isSent() || this.isOpened() || this.isArchived()
  }

  isStarted () {
    return this.status === Status.started
  }

  isStarting () {
    return this.doing === Transitions.start
  }

  open () {
    if (!this.canOpen()) { throw Error('invalid transition state') }
    return this.state(Transitions.open)
  }

  wasOpened () {
    return this.status.some(s => s.status === Status.open)
  }

  send () {
    if (!this.canSend()) { throw Error('invalid transition state') }
    return this.state(Transitions.send)
  }

  canOpen () {
    return this.isSent()
  }

  isOpened () {
    return this.status === Status.opened
  }

  isOpening () {
    return this.doing === Transitions.open
  }

  isMet () {
    return this.status === Status.met
  }

  archive () {
    if (!this.canArchive()) { throw Error('invalid transition state') }
    return this.state(Transitions.archive)
  }

  canArchive () {
    return this.isSent() || this.isStarted() || this.isOpened() || this.isSchedulingMeeting() || this.isMet()
  }

  isArchived () {
    return this.status === Status.archived
  }

  isArchiving () {
    return this.doing === Transitions.archive
  }

  unarchive () {
    if (!this.canUnarchive()) { throw Error('invalid transition state') }
    const [was] = Array.from(this.statuses).slice(1)
    const to = {
      [Status.sent]: Transitions.send,
      [Status.opened]: Transitions.open,
      [Status.started]: Transitions.start,
      [Status.scheduling_meeting]: Transitions.schedule_meeting,
      [Status.met]: Transitions.meet
    }[was.status]
    return this.state(to || Transitions.unarchive)
  }

  canUnarchive () {
    return this.isArchived() || this.isRejected()
  }

  isUnarchiving () {
    return this.doing === Transitions.unarchive
  }

  scheduleMeeting () {
    if (!this.canScheduleMeeting()) { throw Error('invalid transition state') }
    return this.state(Transitions.schedule_meeting)
  }

  canScheduleMeeting () {
    return this.search?.urlCalendar && !this.search?.autoCalendar && !this.wasSchedulingMeeting() && (this.isSent() || this.isStarted() || this.isOpened())
  }

  isSchedulingMeeting () {
    return this.status === Status.scheduling_meeting
  }

  isScheduleMeeting () {
    return this.doing === Transitions.schedule_meeting
  }

  wasSchedulingMeeting () {
    return this.statuses.slice(1).some(s => s.status === Status.scheduling_meeting)
  }

  meet () {
    if (!this.canMeet()) { throw Error('invalid transition state') }
    return this.state(Transitions.meet)
  }

  canMeet () {
    return this.isSent() || this.isOpened() || this.isStarted() || this.isSchedulingMeeting()
  }

  isMeet () {
    return this.doing === Transitions.meet
  }

  load () {
    if (this.loading) {
      return this.loading
    }
    if (this.loaded) {
      return Promise.resolve(this)
    }
    if (this.id) {
      this.loading = this.socket.service(service('READ'), { id: this.id })
        .then(this.setData.bind(this))
        .then(() => this.setData(Object.assign({}, this, { loading: false, loaded: true })))
      return this.loading
    }
    if (this.candidate.id && this.recruiter.id) {
      this.loading = this.socket.service(service('QUERY'), {
        alias: 'c',
        class: 'Conciliation',
        parameters: [
          { type: cond('Parameter'), name: 'recruiter', value: this.recruiter.id },
          { type: cond('Parameter'), name: 'candidate', value: this.candidate.id }
        ],
        conditions: [
          {
            type: cond('Equals'),
            value: 'recruiter',
            subject: {
              type: cond('Field'),
              name: 'c.recruiter'
            }
          },
          {
            type: cond('Equals'),
            value: 'candidate',
            subject: {
              type: cond('Field'),
              name: 'c.candidate'
            }
          }
        ]
      })
        .then(list => list[0])
        .then(this.setData.bind(this))
        .then(() => Object.assign(this, {
          loading: false,
          loaded: true
        }))
    }
    return this.loading
  }

  marshall (...fields) {
    if (fields.length === 0) {
      return Object.assign(
        pick(['id', 'statuses', 'status', 'bookmark'], this),
        {
          recruiter: this.recruiter?.id,
          candidate: this.candidate?.id,
          search: this.search?.id
        }
      )
    }
    const interviewData = Object.assign({}, {
      recruiter: this.recruiter?.id,
      candidate: this.candidate?.id,
      search: this.search?.id
    }, this)
    return pick(fields, interviewData)
  }

  save (...fields) {
    try {
      this.updating = this.socket.service(service('SAVE'), this.marshall(...(['id'].concat(fields))))
      this.updating.then(() => this.setData({ updating: null }))
      return this.updating
    } catch {
      this.setData({ updating: null })
    }
  }

  roomId (account) {
    return [this.domain, account.id, this.candidate.id]
      .filter(t => t && t.length > 0)
      .join('/')
  }

  static isValidRoom (id) {
    // @todo more accurate uuid regexp
    return /^\/room\/interview\/[0-9a-f-]+\/[0-9a-z-]+$/.test(id) || /^\/conciliator\/[0-9a-f-]+\/[0-9a-z-]+$/.test(id)
  }
}

Conciliation.list = function (opts, socket, cancel) {
  if (opts.sql) {
    return sqlEntities(opts, socket, cancel)
  }
  return Conciliation.slip(opts, socket, cancel)
}

function sqlEntities (opts, socket, cancel) {
  const args = {
    alias: 'c',
    class: 'Conciliation',
    parameters: [],
    conditions: [],
    joins: []
  }
  if (opts.states) {
    let states = opts.states
    if (typeof opts.states === 'string') {
      states = [opts.states]
    }
    if (Array.isArray(states)) {
      args.parameters.push({
        type: cond('Parameter'),
        name: 'status',
        value: states.filter(s => Status[s])
      })
      args.conditions.push({
        type: cond('In'),
        value: 'status',
        subject: {
          type: cond('Field'),
          name: 's.status'
        }
      })
    }
    // current
    args.parameters.push({
      type: cond('Parameter'),
      name: 'current',
      value: true
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'current',
      subject: {
        type: cond('Field'),
        name: 's.current'
      }
    })
    // joins
    args.joins.push({
      field: {
        type: cond('Field'),
        name: 'c.statuses'
      },
      alias: 's',
      type: 'right'
    })
  }
  if (opts.recruiter) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'recruiter',
      value: opts.recruiter.id
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'recruiter',
      subject: {
        type: cond('Field'),
        name: 'c.recruiter'
      }
    })
  }
  if (opts.candidates) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'candidate',
      value: opts.candidates.map(c => c.id)
    })
    args.conditions.push({
      type: cond('In'),
      value: 'candidate',
      subject: {
        type: cond('Field'),
        name: 'c.candidate'
      }
    })
  }
  if (opts.searches && Array.isArray(opts.searches) && opts.searches.length > 0) {
    let searchesParam
    if (typeof opts.searches[0] === 'string') {
      searchesParam = opts.searches
    } else {
      searchesParam = opts.searches.map(s => s.id)
    }
    args.parameters.push({
      type: cond('Parameter'),
      name: 'search',
      value: searchesParam
    })
    args.conditions.push({
      type: cond('In'),
      value: 'search',
      subject: {
        type: cond('Field'),
        name: 'c.search'
      }
    })
  }

  if (opts.count) {
    args.count = true
  } else {
    if (opts.limit) {
      args.limit = opts.limit
    }

    if (opts.offset) {
      args.offset = opts.offset
    }
    if (opts.order) {
      args.order_by = [
        {
          field: {
            type: 'YesWeChat\\ServiceEntityBundle\\Query\\Condition\\Field',
            name: `s2.${opts.order.field}`
          },
          direction: opts.dir?.toUpperCase() || 'ASC'
        }
      ]
      args.parameters.push({
        type: cond('Parameter'),
        name: opts.order.status,
        value: opts.order.status
      })
      args.conditions.push({
        type: cond('Equals'),
        value: opts.order.status,
        subject: {
          type: cond('Field'),
          name: 's2.status'
        }
      })
      args.joins.push({
        field: {
          type: cond('Field'),
          name: 'c.statuses'
        },
        alias: 's2',
        type: 'right'
      })
    }
  }

  return socket.service(service('QUERY'), args, { cancel }).then(data => {
    if (opts.count) {
      return data.count
    }
    return data.map(c => Object.assign(Conciliation.create(c, socket), { loaded: true }))
  })
}

Conciliation.compare = function (a, b) {
  if (!a.conciliation?.sentAt || !b.conciliation?.sentAt) {
    return 0
  }
  if (a.conciliation.sentAt > b.conciliation.sentAt) {
    return -1
  }
  if (a.conciliation.sentAt < b.conciliation.sentAt) {
    return 1
  }
  return 0
}

Conciliation.slip = function (opts, socket, cancel) {
  const req = new Solr({
    entity: 'ConciliationStatus',
    raw: false
  })
  // status sent is our sort params
  req.query.push(eq('status', Status.sent))

  // rebase the conciliation as our base object
  req.group('conciliation')
  const cr = req.relation({
    entity: 'Conciliation',
    name: 'conciliation'
  })
  const cj = req.join({ entity: 'Conciliation' })
  if (opts.recruiter) {
    cj.query.push(eq('recruiter', opts.recruiter.id))
  }

  if (typeof opts.bookmark !== 'undefined' && opts.bookmark !== null) {
    cj.query.push(eq('bookmark', '' + opts.bookmark))
  }
  const status = req.join({
    entity: 'ConciliationStatus'
  })
    .from('conciliation')
    .to('conciliation')
  status.query.push('current:true')

  // restrict current state
  if (opts.states) {
    let states = opts.states
    if (typeof opts.states === 'string') {
      states = [opts.states]
    }
    status.query.push(or(...states.map(eq('status'))))
  }
  cr.relation({
    entity: 'ConciliationStatus',
    name: 'statuses'
  })

  if (opts.candidates && opts.candidates.length) {
    cj.query.push(or(...collectIds(opts.candidates).map(eq('candidate'))))
  }
  if (opts.searches && Array.isArray(opts.searches) && opts.searches.length > 0) {
    cj.query.push(or(...collectIds(opts.searches).map(eq('search'))))
  }
  if (opts.query) {
    req.join({
      entity: 'Candidate',
      query: search('global_search', opts.query)
    })
  }
  if (opts.count) {
    req.limits(0)
  } else {
    cr.relation({
      entity: 'Candidate',
      name: 'candidate'
    })
    req.limits(opts.limit, opts.offset)
    req.sorts('date', 'desc')
  }

  return socket.service('entity_solr/QUERY', req.send(), { cancel }).then(data => {
    if (opts.count) {
      return data.numFound
    }
    if (opts.raw) {
      return data
    }
    data.docs = data.docs.map(({ conciliation }) => {
      return Object.assign(Conciliation.create(conciliation.docs[0], socket), { loaded: true })
    })
    return data
  })
}
