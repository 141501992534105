import { EventEmitter } from 'eventemitter3'
import { uniq } from '@/fun.js'
import debug from 'debug'
const log = debug('update')

export default {
  mounted () {
    Object.entries(this.$props).forEach(([key, value]) => {
      log('%s key %s', this.$options.name, key)
      if (value instanceof EventEmitter) {
        value.on('update', this.onEntityUpdate, this)
        log('watch %s key %s', this.$options.name, key)
        this.$watch(key, (value, old) => {
          value && value.on('update', this.onEntityUpdate, this)
          old.off('update', this.onEntityUpdate, this)
        })
      }
    })
  },
  unmounted () {
    Object.values(this.$props).forEach(prop => {
      if (prop instanceof EventEmitter) {
        prop.off('update', this.onEntityUpdate, this)
      }
    })
  },
  methods: {
    onEntityUpdate () {
      this.$key = uniq()
      this.$forceUpdate()
      log('force')
    }
  },
  data () {
    return { $key: uniq() }
  }
}
