import Entity from './entity'
import Trade from './trade'
import Location from './location'
import { factory } from '@/fun.js'
import Api from '@/entities/api'

const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`

class Search extends Entity {
  constructor (data, socket) {
    super(data, socket)
    this.conciliations = []
  }

  static create (data, socket) {
    return factory(Search, data, socket)
  }

  watch () {
  }

  destroy () {
    this.removeAllListeners()
  }

  static api (socket) {
    return new Api(socket, 'search')
  }

  setData (data) {
    if (data === null || typeof data !== 'object') {
      return this
    }
    const surcharge = {}

    if (data.location && !(data.location instanceof Location)) {
      surcharge.location = new Location(data.location, this.socket)
    }

    if (data.trade && !(data.trade instanceof Trade)) {
      surcharge.trade = new Trade(data.trade, this.socket)
    }
    if (data.createdAt && !(data.createdAt instanceof Date)) {
      surcharge.createdAt = new Date(data.createdAt)
    }
    return super.setData(Object.assign({}, data, surcharge))
  }

  async loadNestedEntities () {
    await Promise.all([
      (this.location && !this.location.name) ? this.location.load() : true,
      (this.trade && !this.trade.name) ? this.trade.load() : true
    ])
    this.emit('update')

    return this
  }
}

Search.prototype.entityClass = 'Search'
Search.prototype.entityFields = ['id', 'location', 'trade', 'recruiter', 'createdAt', 'contracts', 'active', 'file']

Search.list = async function (opts, socket) {
  const args = {
    alias: 's',
    class: 'Search',
    parameters: [],
    conditions: [],
    joins: []
  }
  opts = Object.assign({}, opts) // shallow copy to avoid side effect
  if ('account' in opts) {
    if (opts.account.role !== 'ADMIN') {
      args.parameters.push({
        type: cond('Parameter'),
        name: 'account',
        value: opts.account.id
      })
      args.conditions.push({
        type: cond('Equals'),
        value: 'account',
        subject: {
          type: cond('Field'),
          name: 'a.id'
        }
      })
      args.joins.push({
        field: {
          type: cond('Field'),
          name: 's.recruiterAccounts'
        },
        alias: 'a',
        type: 'right'
      })
    }
    if (!opts.recruiter) {
      opts.recruiter = opts.account.recruiter
    }
  }

  if (opts.recruiter) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'recruiter',
      value: opts.recruiter.id
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'recruiter',
      subject: {
        type: cond('Field'),
        name: 's.recruiter'
      }
    })
  }
  if ('active' in opts) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'active',
      value: opts.active
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'active',
      subject: {
        type: cond('Field'),
        name: 's.active'
      }
    })
  }
  const list = await socket.service('entity.Search/QUERY', args)
  return list.map(l => Search.create(l, socket))
}

Search.loadIds = async function (searches, socket, cancel) {
  if (searches.length < 1) {
    return
  }
  let batch = Promise.resolve()
  const chunk = 10
  for (let i = 0; i < searches.length; i += chunk) {
    const { promise, run } = loadBatch(searches.slice(i, i + chunk), socket, cancel)
    batch.then(run)
    batch = promise
  }
  return batch
}
function loadBatch (searches, socket, cancel) {
  let run
  const promise = new Promise(function (resolve, reject) {
    run = async function () {
      try {
        const list = await socket.service('entity.Search/QUERY', {
          alias: 's',
          class: 'Search',
          parameters: [
            { type: cond('Parameter'), name: 'id', value: searches.map(c => c.id) }
          ],
          conditions: [
            {
              type: cond('In'),
              value: 'id',
              subject: {
                type: cond('Field'),
                name: 's.id'
              }
            }
          ]
        }, { cancel })
        list.forEach(d => {
          searches
            .filter(s => d.id === s.id)
            .forEach(s2 => {
              s2.setData(d)
              s2.loading = false
            })
        })
        resolve(searches)
      } catch (err) {
        reject(err)
        searches.forEach(c => {
          c.loading = false
        })
      }
    }
  })
  searches.forEach(c => {
    c.loading = promise.then(() => c)
  })
  return { promise, run }
}

export default Search
