import axios from 'axios'
import clone from 'ramda/src/clone'

export default class Api {
  constructor (socket, path) {
    this.socket = socket
    this.path = path
    this.ws = axios.create({
      baseURL: socket.config.socketServer
    })
    this.api = axios.create({
      baseURL: socket.config.apiServer
    })
  }

  async headers (headers = {}) {
    // clone headers and include jwt
    return Object.assign({ Authorization: `Bearer ${await this.socket.auth.getToken()}` }, headers)
  }

  async list (params = {}, opts = {}) {
    opts = clone(opts)
    opts.method = 'get'
    opts.headers = await this.headers(opts.headers)
    opts.url = this.path
    return this.ws(Object.assign(opts, { params }))
  }

  async create (opts = {}) {
    opts.method = 'post'
    opts.headers = await this.headers(opts.headers)
    opts.url = this.path
    return opts.api ? this.api(opts) : this.ws(opts)
  }
  
  async get (params = {}, headers = {}, path = '', mixin = {}) {
    return this.ws(Object.assign(mixin, {
      method: 'get',
      headers: await this.headers(headers),
      cancelToken: headers.cancel || headers.cancelToken,
      url: this.path + path,
      params
    }))
  }
}
