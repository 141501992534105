<template>
  <svg v-bind="$props" viewBox="0 0 60 60">
    <path d="M16.79,41.84,28.26,30.37,16.79,18.9l1.44-1.44L29.7,28.93,41.18,17.46l1.44,1.39L31.14,30.37,42.66,41.89l-1.44,1.44L29.7,31.81,18.23,43.28Z"/>
  </svg>
</template>

<script>
import icon from './mixins'

export default {
  name: 'hippolyte.atom.icon.close',
  mixins: [icon('35px')]
}
</script>
