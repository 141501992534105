<template>
  <svg v-bind="$props" viewBox="0 0 14 18">
    <path d="M7.22222 9H13.4444M13.4444 9L10.7778 11.6667M13.4444 9L10.7778 6.33333M13.4444 3.66667V2.77778C13.4444 2.30628 13.2571 1.8541 12.9237 1.5207C12.5903 1.1873 12.1382 1 11.6667 1H2.77778C2.30628 1 1.8541 1.1873 1.5207 1.5207C1.1873 1.8541 1 2.30628 1 2.77778V15.2222C1 15.6937 1.1873 16.1459 1.5207 16.4793C1.8541 16.8127 2.30628 17 2.77778 17H11.6667C12.1382 17 12.5903 16.8127 12.9237 16.4793C13.2571 16.1459 13.4444 15.6937 13.4444 15.2222V14.3333" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import icon from './mixins'

export default {
  name: 'hippolyte.atom.icon.logout',
  mixins: [icon('20px')]
}
</script>
